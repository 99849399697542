import { createRouter, createWebHistory } from 'vue-router';
import StepPage from '@/components/step-page.vue';
import MainView from '../views/MainView.vue';
import LoginView from '../views/LoginView.vue';
import CongratsPage from '../components/congrats-page.vue';
import ProgressPage from '../components/progress-page.vue';
import TenacyGame from '../components/tenacy-game';

const routes = [
  {
    path: '/',
    name: 'home',
    component: MainView,
    children: [
      {
        path: '/congrats',
        name: 'congrats',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: CongratsPage,
      },
      {
        path: '/progress',
        name: 'progress',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: ProgressPage,
      },
      {
        path: '/step/:id',
        name: 'step',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: StepPage,
        props: true
      },
      {
        path: '/tenacy-game',
        name: 'tencay-game',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: TenacyGame,
      },

    ],
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: LoginView,
  },
  // default redirect to home page
  { path: '/:pathMatch(.*)*', redirect: '/' },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  const { $cookies } = router.app.config.globalProperties
  if (to.fullPath !== "/login") {
    const cookie = $cookies.get('user')
    if (cookie) {
      next()
    } else {
      next({path: '/login'});
    }
  } else {
    next()
  }
})
export default router;
