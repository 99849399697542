import Axios from 'axios';
import VueCookies from 'vue3-cookies';
import { createApp } from 'vue';
import config from './config';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import 'materialize-css/dist/css/materialize.min.css';

Axios.defaults.baseURL = config.base_url;
Axios.defaults.withCredentials = true;

const app = createApp(App).use(store).use(router);

app.use(VueCookies, {
  expireTimes: '30d',
  path: '/',
  domain: '',
  secure: true,
  sameSite: 'None',
});

Axios.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401 || error.response.status === 403 || error.response.status === 404 || error.response.status === 500) {
    window.location = '/login';
  }
});

app.config.globalProperties.$http = Axios;
router.app = app

app.mount('#app');
