<template>
  <div class="container login">
    <img class="responsive-img logo" src="../assets/Tenacy.svg" alt="tenacy logo">
    <h2>Onboarding</h2>
    <button class="login-button" type="submit" name="action" @click="login">
        <img class="microsoft-logo" src="../assets/microsoft.png" alt="microsoft logo">
        <span>Connexion</span>
    </button>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'LoginView',
  components: {},
  methods: {
    login() {
      window.location = 'https://api.onboarding.tenacy.io/microsoft/login';
    },
  },
};
</script>
<style>
.logo{
  width: 80%
}
h2{
  font-weight: 600;
}
.login{
  width: 100vw;
  height: 100vh;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:#FFFFFF
}
.login-button{
  animation: wiggle 2s linear infinite;
  background-color: #FFFFFF;
  color:#0A4650;
  width: 80%;
  border: 1px solid #0A4650;
  border-radius: 4px;
  padding: 1rem;
  margin-top: 4rem;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.microsoft-logo{
  height: 2rem;
  width: 2rem;
  margin-right: .5rem;
}

/* Keyframes */
@keyframes wiggle {
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
}

</style>
