<template>
  <div class="progress">
    <div class="row ">
      <h3>Avancement</h3>
    </div>
    <div v-if="all_users.length > 0" v-for="user in all_users" :key="user.email" class="progress-card row">
        <div class="col s5 left-section">
          <p class="name">{{ getUserName(user.email)}}</p>
          <p class="date">Inscrit : {{ updateCurrentDate(user.created)}}</p>
        </div>
        <div class="col s7 right-section">
          <div class="step-title ">
            <p class="step">Etape: {{doneSteps(user)}}/{{ user.steps.length }}</p>
          </div>
          <div class="row score-container">
            <div v-for="step in user.steps" :key="step[2]" >
              <svg class="svg" id="Calque_2" data-name="Calque 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                <g id="on" v-if="step[1]">
                  <g id="on-2" data-name="on">
                    <rect class="cls-1" width="16" height="16" rx="2" ry="2"/>
                    <rect class="cls-3" x="4.74" y="5.96" width="2.69" height="6.04" rx="1" ry="1" transform="translate(-4.57 6.94) rotate(-45)"/>
                    <rect class="cls-3" x="5.05" y="6.69" width="8.73" height="2.69" rx="1" ry="1" transform="translate(-2.93 9.01) rotate(-45)"/>
                  </g>
                </g>
                <g id="off" v-else>
                  <rect id="off-2" data-name="off" class="cls-2" x=".5" y=".5" width="15" height="15" rx="1.5" ry="1.5"/>
                </g>
              </svg>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'progress-page',
  data() {
    return {
      all_users: [],

    };
  },
  methods:{
    doneSteps(user){
      return user.steps.filter(item=> item[1]).length
    },
    getUserName(email){
      const [name, domain] = email.split("@");
      const [firstName, lastName] = name.split(".");
      return firstName.charAt(0).toUpperCase() + firstName.slice(1) +' '+ lastName.toUpperCase()
    },
    updateCurrentDate(string_date) {
      const date = new Date(string_date);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = String(date.getFullYear()).slice(-2);
      this.currentDate = `${day}/${month}/${year}`;
      return this.currentDate
    }
  },
  mounted(){
    this.all_users = []
    this.$http.get('graph/').then((resp) => {
      this.$store.commit('setUsers', resp.data.objects)
      this.users = this.$store.getters.getUsers
      this.all_users = this.users.map(u => { return {id: u.identity, email:u.email, created: u.created}}).filter((user)=>user.email !== 'string')
      this.all_users.forEach(u => {
        this.$http.get('graph/'+ u.id).then((resp) => {
          if (resp.data.ok) {
            u.steps = resp.data.object[0]
          }
        })
      })
    }).catch((err) => {
      this.$router.push('/login');
    });



  }
};
</script>

<style scoped>
.cls-1 {
  fill: #fff;
}

.cls-2 {
  stroke: #50ebd2;
}

.cls-2, .cls-3 {
  fill: #0a4650;
}
.progress{
  display: flex;
  margin-top: 0px;
  flex-direction: column;
  height:100%;
  padding: 1rem;
  background: linear-gradient(180deg, rgba(29,70,81,1) 0%, rgba(29,70,81,0.7147233893557423) 35%, rgba(50,104,99,0.3113620448179272) 100%);
}

.progress h3{
  font-weight: 600;
  text-align: left;
}
.progress-card{
  background-color: #0A4650;
  width: 100%;
  border:2px solid #50EBD2;
  border-radius: 5px;
  margin-bottom: 1rem !important;
}
.left-section{
  align-items: center;
}
.name{
  font-weight: bold;
  margin-bottom: 0;
  font-size: 1rem;
  text-align: left;
}
.step-title{
  width: 100%;
  text-align: right;
  padding-right: .8rem;
}
.date{
  color:#50EBD2;
  font-size: 12px;
  margin-top: .35rem;
  text-align: left;
}

.step{
  font-weight: 600;
  margin-bottom: .4rem;
  margin-left: -.9rem;
}

.right-section{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start
}
.svg{
  width: 1.2rem;
  height: 1.2rem;
  margin-right: .1rem;
  display: flex;
}

.score-container{
  display: flex;
  flex-direction: row;
}


</style>
