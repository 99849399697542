<template>
  <div class="layout-navbar-fixed row">
    <div class="left">
      <i @click="goToHome" style="font-size: 2.2rem; color: #50EBD2" v-if="$route.name != 'home'" class="material-icons">arrow_back</i>
    </div>
    <div class="col-header">
      <img src="../../src/assets/Tenacy.svg" alt="" class="logo-tenacy-header">
    </div>
    <div class="right">
    </div>
  </div>
  <div v-if="is_home" class="child-view container">
    <div class="row">
      <div class="col s12 m12 l12">
        <button class="waves-effect waves-light btn-large main-button" @click="redirect('/tenacy-game')">
          <span class="border-button">
          Tenacy Game
        </span>
        </button>
        <button class="waves-effect waves-light btn-large main-button" @click="redirect('/progress')">Progression</button>
        <button disabled class="waves-effect waves-light btn-large main-button">Coming soon...</button>
      </div>
    </div>
  </div>
  <router-view v-else></router-view>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'MainView',
  components: {
  },
  data() {
    return {
      is_home: true,
    };
  },
  methods: {
    getUserInfo() {
      this.$http.get('users/me').then((resp) => {
        this.$store.commit('setUser', resp.data.objects)
      }).catch((err) => {
        this.$router.push('/login');
      });
    },
    getUsersInfo() {
      this.$http.get('graph/').then((resp) => {
        this.$store.commit('setUsers', resp.data.objects)
      }).catch((err) => {
        this.$router.push('/login');
      });
    },
    redirect(link){
      this.is_home = false;
      this.$router.push(link);
    },
    goToHome() {
      if(this.$route.name == 'step' || this.$route.name == 'congrats'){
        this.$router.push('/tenacy-game');
      }else{
        this.is_home = true;
        this.$router.push('/');
      }
    },
  },
  mounted() {
    this.getUserInfo()
    this.getUsersInfo()
  }
};
</script>

<style lang="scss">
.row {
  margin-bottom: 0px !important;
}
.col-header {
  display: flex;
}
.left, .right {
  flex: 1;
  justify-content: left;
}
.main-button {
  width: 80%;
  border: 1px solid #0A4650;
  border-radius: 5px;
  margin-top: 3rem;
  font-size: 18px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-transform: none !important;
  background-color: #50EBD2 !important;
  color: black !important;
  font-weight: 700 !important;
  box-shadow: 0 0 0 5px #50EBD2,
  inset 0 0 0 2px black !important;
}

.logo-tenacy-header {
  width: 100% !important;
  height: 30px;
}
.layout-navbar-fixed {
  background-color: #0a4650;
  height: 8vh;
  display: flex;
  align-items: center;
  border-bottom: #50EBD2 1px solid;
}
.child-view {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 92vh;
}

.disabled {
  box-shadow: 0 0 0 5px #dfdfdf, inset 0 0 0 2px #9f9f9f !important;
}
</style>
