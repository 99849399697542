<template>
  <div class="step container">
    <div style="width: 100%;" v-if="step">
      <div class="row">
        <h3>
          {{ step[0] }}
        </h3>
        <span  v-html="step[3]">
        </span>
      </div>
      <div v-if="step[2] == 'CC'">
        <img src="/img/hodm.png" width="150"
             alt="Head of Digital Marketing"/>
      </div>
      <div class="row" :class=" step[2] == 'CC' ? '' : 'code-block'">
        <h5 v-if="progression!==true">Code secret</h5>
        <input
          v-if="progression!==true"
          v-model="secret"
          class="custom-input"
        />
        <button v-if="progression===true" class="waves-effect waves-light btn-large submit-button" style="width: 100%" @click="goToTenacyGame">Retour</button>
        <button v-else class="waves-effect waves-light btn-large submit-button" style="width: 100%" @click="goToSteps">Valider</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'step-page',
  data() {
    return {
      secret: null,
      step: null,
      progression: null,
      all_steps: [],
      steps: [
        {
          name: 'Start',
          identifier: 'START',
          done: false,
          url: 'Fuse.svg',
        },
        {
          name: 'Office strikes back',
          identifier: 'OFB',
          done: true,
          url: 'RH.svg',
        },
        {
          name: 'Raccoons !',
          identifier: 'RACCOONS',
          done: false,
          url: 'Raccoon.svg',
        },
        {
          name: 'Les boss',
          identifier: 'BOSS',
          done: false,
          url: 'Crown.svg',
        },
        {
          name: 'Customer',
          identifier: 'CUSTOMER',
          done: false,
          url: 'Crown.svg',
        },
        {
          name: 'Tenacy Evangelist',
          identifier: 'TE',
          done: false,
          url: 'CSM.svg',

        },
        {
          name: 'Market Master',
          identifier: 'MM',
          done: true,
          url: 'Market.svg',
        },
        {
          name: 'Dynamite',
          identifier: 'DT',
          done: false,
          url: 'DT.svg',
        },
        {
          name: 'AI Pacino',
          identifier: 'AP',
          done: true,
          url: 'Crown.svg',
        },
        {
          name: 'Marque',
          identifier: 'MARQUE',
          done: false,
          url: 'Crown.svg',
        },
      ],
      identifier: null
    };
  },
  components: {},
  computed: {
    progression() {
      return this.step[1]
    }
  },
  methods: {
    getUserInfo() {
      this.$http.get('users/me').then((resp) => {
        this.$store.commit('setUser', resp.data.objects)
        this.$router.push('/tenacy-game')
      });
    },
    goToTenacyGame() {
      this.$router.push('/tenacy-game')
    },
    goToSteps() {
      let user_id = this.step[4]
      let step_id = this.step[2]
      this.$http.put('graph/' + user_id + '/' + step_id + '?secret_code=' + this.secret, {}, {withCredentials: true}).then((resp) => {
        if (resp.data.ok) {
          this.getUserInfo()
        } else {
          alert('Mauvais mot de passe')
        }
      })
    }
  },
  mounted() {
    this.all_steps = this.$store.getters.getSteps
    this.identifier = this.$route.params.id
    this.step = null;
    for (let i = 0; i < this.all_steps.length; i++) {
      if (this.all_steps[i][2] === this.identifier) {
        this.step = this.all_steps[i];
        break;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.submit-button {
  height: 40px;
  background-color: #114650;
  color: #50EBD2;
  width: 80%;
  border: 1px solid #50EBD2;
  border-radius: 4px;
  padding: 1rem;
  margin-top: 0.5rem;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-transform: none;
}

.step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 100vw;
  padding: 2rem;
  /*background: rgb(29,70,81);*/
  background: linear-gradient(180deg, rgba(29, 70, 81, 1) 0%, rgba(29, 70, 81, 0.7147233893557423) 70%, rgba(50, 104, 99, 0.3113620448179272) 100%);
}

.step p {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  text-align: left;
}

.custom-input {
  background-color: white;
  border-radius: 5px;
}

.code-block {
  margin-top: 3rem;
}

.code-block h5 {
  font-weight: 700;
}
</style>
