<template>
  <div class="congrats container">
      <h2>
        Félicitation
      </h2>
      <p>
        Tu as maintenant effectué toutes les étapes
        d'intégration et rencontré toutes les équipes et
        collaborateurs de chez Tenacy.
      </p>
      <p>
        Tu es désormais prêt pour relever tes missions !
        Pour te féliciter et symboliser ton intégration,
        nous t'offrons le pull TENACY.
        (à récupérer au bureau Marketing)
      </p>
      <p>Bienvenue dans l'aventure !</p>

    <img class="responsive-img congrats-image" src="../assets/congrate.svg" alt="badge">
  </div>
</template>

<script>

export default {
  name: 'congrats-page',
  components: {
  },
  methods: {
  },
};
</script>

<style scoped>
.congrats{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height:92vh;
  padding: 1rem;
  /*background: rgb(29,70,81);*/
  background: linear-gradient(180deg, rgba(29,70,81,1) 0%, rgba(29,70,81,0.7147233893557423) 35%, rgba(50,104,99,0.3113620448179272) 100%);
}
.congrats p{
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  text-align: justify;
}
.congrats p:last-of-type{
  font-size: 20px
}
.congrats-image{
  height: 50%;
  width: 50%;
  margin-top: 1rem;
}
</style>
