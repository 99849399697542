import { createStore } from 'vuex';

export default createStore({
  state: {
    user: {
      email: null,
      user_steps: [],
    },
    is_login: true,
    token: '',
    users: [],
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getlogin(state) {
      return state.is_login;
    },
    getUsers(state) {
      return state.users;
    },
    getSteps(state) {
      return state.user.user_steps;
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setUsers(state, users) {
      state.users = users;
    },
  },
  actions: {
  },
  modules: {
  },
});
